import React from "react"
import Layout from "../../../components/sermonLayoutSurprise"
import SEO from "../../../components/seo"

const SermonPost = () => (
  <Layout sermonSrc="https://www.youtube.com/embed/e5lgzd2FiWI">
    <SEO title="Holiness and Work - Touching Holiness" />
  </Layout>
)

export default SermonPost
